import { render, staticRenderFns } from "./UserToken.vue?vue&type=template&id=107f2df7&scoped=true"
import script from "./UserToken.vue?vue&type=script&lang=js"
export * from "./UserToken.vue?vue&type=script&lang=js"
import style0 from "./UserToken.vue?vue&type=style&index=0&id=107f2df7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107f2df7",
  null
  
)

export default component.exports